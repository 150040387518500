<template>
  <div> </div>
  <!-- <example class="example" />
  <hr />
  <static-dialog class="dialog" title="编辑" :visible="false">
    <div>1234</div>
  </static-dialog> -->

  <div class="dialog">
    <resetPwdDialog :visible="resetDialogVisible" title="密码过于简单，需要重置该默认密码"></resetPwdDialog>
  </div>

</template>
<script>
import resetPwdDialog from "@/views/sys/user/reset.vue";
//import Example from "@/components/example.vue";
//import StaticDialog from "@/components/static-dialog.vue";
export default {
  name: "default",
  //components: {Example,StaticDialog,},
  components: { resetPwdDialog },
  data() {
    return {};
  },
  methods: {},
  mounted() { },
  created() {
    //console.log(this.$route.query.type)
    if (this.$route.query.type == 'simple') {
      this.$message.error("密码过于简单，请重置默认密码");
      //this.$emit('openPwd');
      this.resetDialogVisible = true;
    }
  },
};
</script>